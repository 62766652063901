import '../styles/partnerpage.scss';
import 'react-multi-carousel/lib/styles.css';
import '@splidejs/react-splide/css';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import React, { useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';

import Layout from '../components/layout';
import aegis from '../images/partner/aegis.png';
import api from '../images/partner/api.png';
import logo10 from '../images/partner/Assurity.png';
import bamboo from '../images/partner/bamboo.png';
import bank from '../images/partner/bank.svg';
import bannerimg from '../images/partner/banner.svg';
import b1 from '../images/partner/benefit1.svg';
import b2 from '../images/partner/benefit2.svg';
import b3 from '../images/partner/benefit3.svg';
import brand from '../images/partner/brand.png';
import tlogo from '../images/partner/Bubble Logo.svg';
import company from '../images/partner/company.svg';
import done from '../images/partner/done.svg';
import estate from '../images/partner/estate.svg';
import help from '../images/partner/help.svg';
import hippo from '../images/partner/hippo.png';
import ho from '../images/partner/homeownership.svg';
import hp from '../images/partner/homepurchase.svg';
import hs from '../images/partner/homesearch.svg';
import kk from '../images/partner/kk.png';
import logo6 from '../images/partner/Legal & General.png';
import logo1 from '../images/partner/Nationwide Logo.png';
import logo3 from '../images/partner/NEXT by PacLife.png';
import NEXT from '../images/partner/NEXT.png';
import logo8 from '../images/partner/NIA.png';
import logo7 from '../images/partner/Prudential.png';
import logo4 from '../images/partner/SafeCo Logo.png';
import logo5 from '../images/partner/SBLI Logo.png';
import SBLI from '../images/partner/SBLI.png';
import logo2 from '../images/partner/Stillwater Logo.png';
import Stillwaters from '../images/partner/Stillwaters.png';
import t1 from '../images/partner/table1.svg';
import t2 from '../images/partner/table2.svg';
import t3 from '../images/partner/table3.svg';
import t4 from '../images/partner/table4.svg';
import t5 from '../images/partner/table5.svg';
import t6 from '../images/partner/table6.svg';
import t7 from '../images/partner/table7.svg';
import t8 from '../images/partner/table8.svg';
import tmlogo from '../images/partner/tmlogo.svg';
import logo9 from '../images/partner/Transamerica.png';
import upload from '../images/partner/upload.png';
import ws from '../images/partner/Western_&_Southern_logo.svg.png';
import widgets from '../images/partner/widgets.png';
import wnslogo from '../images/wnslogo.svg';


function PartnerWithUs() {
  const [show, setShow] = useState( false );
  const [isFormSubmitted, setForm] = useState( false );

  const handleClose = () => {
    setShow( false );
    document.querySelector( 'svg' ).style.display = 'inline';
  };
  const handleShow = () => {
    setShow( true );
    document.querySelector( 'svg' ).style.display = 'none';
  };

  const [email, setEmail] = useState( '' );
  const [phone, setPhone] = useState( '' );
  const [companyName, setCompany] = useState( '' );
  const [fullName, setFullName] = useState( '' );
  const [title, setTitle] = useState( '' );

  const images = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10 ];

  function getCookie(){
    const value = `; ${document.cookie}`;
    const parts = value.split( `; ${'hutk'}=` );
    if ( parts.length === 2 ) return parts.pop().split( ';' ).shift();
  }

  const changePhone = ( e ) => {
    let phone = e.target.value;
    if( phone.length < 11 ){
      setPhone( phone );
    }
  };

  async function submitForm(){
    const cookie = getCookie();

    const mailreg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const phoneeeg = /^([0-9]{10})$/;
    if ( mailreg .test( data.email ) == false ) {
      Swal.fire( 'Please enter a valid email.' );
    }
    else if ( phoneeeg.test( data.phone ) == false ) {
      Swal.fire( 'Please enter a valid mobile number.' );
    }

    const data = {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'company',
          value: companyName
        },
        {
          objectTypeId: '0-1',
          name: 'phone',
          value: phone
        },
        {
          objectTypeId: '0-1',
          name: 'full_name',
          value: fullName
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email
        }
      ],
      context: {
        hutk: cookie,
        pageUri: 'getmybubble.com/partnerwithus',
        pageName: 'Partner With Us'
      }
    };

    if( title ){
      data.fields.push( {
        objectTypeId: '0-1',
        name: 'jobtitle',
        value: title
      } );
    }


    const resp = await fetch( 'https://api.hsforms.com/submissions/v3/integration/submit/8926444/b0698963-aa30-41ae-9c5b-35a05de349b5', {
      method: 'POST'
    } );

    const dataForm = await resp.json();

    if( dataForm?.status == 'error' ){
      //
    } else {
      setForm( true );
    }

  }

  return(
    <Layout header={'menu'}>

      <div className="partner-page-banner">
        <div className="container common-container-space">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-lg-12 d-none d-sm-none d-md-block d-lg-block">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                <Breadcrumb.Item active>Partner with us</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <h2>
                <span>Digital.</span>

                <span>Embedded.</span>

                <span>Smart.</span>
              </h2>

              <h4>The easiest & quickest way to embedded insurace and a new revenue stream.</h4>

              <button type="button" className='common-btn-bg d-block d-sm-block d-md-block d-lg-none' onClick={handleShow}>Book a demo today</button>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <iframe width="615" height="300" src="https://www.youtube.com/embed/B8c976Z37u4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className='bubble-benefits-wrap-box journey-box-wrap whitebg'>
        <div className="container common-container-space">
          <div className="row">
            <div className="col-12">
              <h2>Who does Bubble benefit?</h2>
            </div>
          </div>

          <div className='row'>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="boxwrap">
                <div className="media d-flex">
                  <img className="mr-3" src={bank} alt="Benefit" />

                  <div className="media-body">
                    <h5 className="mt-0">Mortgage Originators, Credit Unions, Banks:</h5>
                  </div>
                </div>

                <ul className="d-none d-sm-none d-md-block d-lg-block">
                  <li>
                      Risk reduction and additional cushion of safety
                  </li>

                  <li>
                      Offering multiple products like home, life, and auto insurance
                  </li>

                  <li>
                      Mitigating friction and transaction time in home buying
                  </li>
                </ul>

                <ul className="d-block d-sm-block d-md-none d-lg-none">
                  <li className="containerlist">
                    <input type="checkbox" id="check_id" />

                    <label htmlFor="check_id"></label>

                    <div className="showlist">
                      <ul>
                        <li>
                          Risk reduction and additional cushion of safety
                        </li>

                        <li>
                          Offering multiple products like home, life, and auto insurance
                        </li>

                        <li>
                          Mitigating friction and transaction time in home buying
                        </li>
                      </ul>
                    </div>

                  </li>

                </ul>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4">
              <div className="boxwrap">
                <div className="media d-flex">
                  <img className="mr-3" src={estate} alt="Benefit" />

                  <div className="media-body">
                    <h5 className="mt-0 d-none d-sm-none d-md-block d-lg-block">Real Estate Companies:</h5>

                    <h5 className="mt-0 d-block d-sm-block d-md-none d-lg-none">Real <br/>Estate Companies:</h5>
                  </div>
                </div>

                <ul className="d-none d-sm-none d-md-block d-lg-block">
                  <li>
                    Co-branded Chrome extension that estimates overall homeownership costs
                  </li>

                  <li>
                    Brand recall with co-branded customer portal and life-long engagement
                  </li>

                  <li>
                    Enhanced “trusted advisors” status digitally to help customers protect their homes & families
                  </li>
                </ul>

                <ul className="d-block d-sm-block d-md-none d-lg-none">
                  <li className="containerlist">
                    <input type="checkbox" id="check_id1" />

                    <label htmlFor="check_id1"></label>

                    <div className="showlist">
                      <ul>
                        <li>
                          Co-branded Chrome extension that estimates overall homeownership costs
                        </li>

                        <li>
                          Brand recall with co-branded customer portal and life-long engagement
                        </li>

                        <li>
                          Enhanced “trusted advisors” status digitally to help customers protect their homes & families
                        </li>
                      </ul>
                    </div>

                  </li>

                </ul>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4">
              <div className="boxwrap">
                <div className="media d-flex">
                  <img className="mr-3" src={company} alt="Benefit" />

                  <div className="media-body">
                    <h5 className="mt-0">Mortgage Companies:</h5>
                  </div>
                </div>

                <ul className="d-none d-sm-none d-md-block d-lg-block">
                  <li>
                    Quick processing
                  </li>

                  <li>
                    Digitalization of process, documentation, and application journey
                  </li>

                  <li>
                    Efficient and smooth integration for greater customer satisfaction
                  </li>
                </ul>

                <ul className="d-block d-sm-block d-md-none d-lg-none">
                  <li className="containerlist">
                    <input type="checkbox" id="check_id2" />

                    <label htmlFor="check_id2"></label>

                    <div className="showlist">
                      <ul>
                        <li>
                          Quick processing
                        </li>

                        <li>
                          Digitalization of process, documentation, and application journey
                        </li>

                        <li>
                          Efficient and smooth integration for greater customer satisfaction
                        </li>
                      </ul>
                    </div>

                  </li>

                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className='journey-box-wrap'>
        <div className="container common-container-space">
          <div className="row">
            <div className="col-12">
              <h2>The journey of home closure and beyond...</h2>
            </div>
          </div>

          <div className='row partner-new-sec'>
            <div className="col-12 col-sm-12 d-block d-sm-block d-md-block d-lg-block col-md-12 col-lg-6">
              <img src={bannerimg} alt="bannerimg" className='bannerimgbox' />
            </div>

            <div className="col-12 col-sm-12 d-none d-sm-none d-md-block d-lg-block col-md-12 col-lg-6">
              <div className="book-demo-box">
                <div className="row">
                  <div className="col-12">
                    <h2>Let’s Work Together</h2>
                  </div>
                </div>

                { !isFormSubmitted ?
                  <form>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <input type="text" onChange={( e ) => setFullName( e.target.value )}   className="common-ip" id="formGroupExampleInput" placeholder="Name" />
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-group">
                          <input type="text" onChange={changePhone}  className="common-ip"
                            value={phone}
                            id="formGroupExampleInput2" placeholder="Phone Number" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <input type="text" onChange={( e ) => setEmail( e.target.value )} className="common-ip" id="formGroupExampleInput" placeholder="Email Id" />
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-group">
                          <input type="text" onChange={( e ) => setCompany( e.target.value )} className="common-ip" id="formGroupExampleInput2" placeholder="Company" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <input type="text" className="common-ip "
                            onChange={( e ) => setTitle( e.target.value )}
                            id="formGroupExampleInput" placeholder="Title (optional)" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        {/* <p>non id maximus vitae ullamcorper vel enim. eu odio ex maximus In Ut amet, laoreet diam orci sit commodo non vitae commodo quis cursus non. Ut volutpat tincidunt sit leo. Nullam non lacus maximus est. viverra leo.</p> */}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <button onClick={submitForm} className="common-btn-bg orangebg-btn">Submit</button>
                      </div>
                    </div>


                  </form>
                  : 'Thanks for submitting the form.'}
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col-12 col-md-12 col-lg-4 mobsec">
              <div className="media d-flex boxwrap align-items-center">
                <img className="mr-3" src={hs} alt="Home Search" />

                <div className="media-body">
                  <h5 className="mt-0">Home <span>Search</span></h5>

                  <div className="containerlist d-none">
                    <input type="checkbox" id="check_view" />

                    <label htmlFor="check_view"></label>

                    <div className="showlist">
                      <p>Home searchDonec scelerisque Praesent enim. sodales. Nunc elit ultrices ex.</p>
                    </div>
                  </div>

                  {/* <a href="">Learn More</a> */}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4 mobsec">
              <div className="media d-flex boxwrap align-items-center">
                <img className="mr-3" src={hp} alt="Home Purchase" />

                <div className="media-body">
                  <h5 className="mt-0">Home <span>Purchase</span></h5>

                  <div className="containerlist d-none">
                    <input type="checkbox" id="check_view2" />

                    <label htmlFor="check_view2"></label>

                    <div className="showlist">
                      <p>Home searchDonec scelerisque Praesent enim. sodales. Nunc elit ultrices ex.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4 mobsec">
              <div className="media d-flex boxwrap align-items-center">
                <img className="mr-3" src={ho} alt="Home Ownership" />

                <div className="media-body">
                  <h5 className="mt-0">Home <span>Ownership</span></h5>

                  <div className="containerlist d-none">
                    <input type="checkbox" id="check_view3" />

                    <label htmlFor="check_view3"></label>

                    <div className="showlist">
                      <p>Home searchDonec scelerisque Praesent enim. sodales. Nunc elit ultrices ex.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bubble-benefits-wrap journey-box-wrap'>
        <div className="container common-container-space">
          <div className="row">
            <div className="col-12">
              <h2>How Bubble benefits you</h2>
            </div>
          </div>

          <div className='row'>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="media boxwrap">
                <img className="mr-3" src={b1} alt="Benefit" />

                <div className="media-body">
                  <h5 className="mt-0">Residual revenue</h5>

                  <p>Ancillary revenue stream with added sales through Bubble platform.</p>

                  {/* <a href="">Learn More</a> */}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4">
              <div className="media boxwrap">
                <img className="mr-3" src={b2} alt="Benefit" />

                <div className="media-body">
                  <h5 className="mt-0">Transaction speed</h5>

                  <p>Shorter processes for quick insurance buying makes for faster closing.</p>

                  {/* <a href="">Learn More</a> */}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4">
              <div className="media boxwrap">
                <img className="mr-3" src={b3} alt="Benefit" />

                <div className="media-body">
                  <h5 className="mt-0">Customer engagement</h5>

                  <p>Bubble features like HomeScore, Bubble LifeScore<sup>TM</sup>, and predicted cost estimates keep customers engaged.</p>

                  {/* <a href="">Learn More</a> */}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="partner-table">
        <div className="container common-container-space">
          <div className="row">
            <div className="col-12">
              <h2>Why partner with Bubble?</h2>
            </div>

            <div className="col-12">
              <Table bordered>
                <thead>
                  <tr>
                    <th>
                      <span>Bubble</span> Advantage
                    </th>

                    <th>
                      <img src={tlogo} alt="logo" className="d-none d-sm-none d-md-none d-lg-block" />

                      <img src={tmlogo} alt="logo" className="d-block d-sm-block d-md-block d-lg-none" />
                    </th>

                    <th className="lasthead">All other digital agencies</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div className="media d-flex align-items-center justify-content-start startdiv">
                        <img className="mr-3" src={t1} alt="t1" />

                        <div className="media-body">
                          <h5 className="mt-0 mb-0">Sale, integration, claims & service by Bubble</h5>
                        </div>
                      </div>
                    </td>

                    <td>
                      <img src={done} alt="done" />
                    </td>

                    <td>
                      <img src={help} alt="help" />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="media d-flex align-items-center justify-content-start startdiv">
                        <img className="mr-3" src={t2} alt="t2" />

                        <div className="media-body">
                          <h5 className="mt-0 mb-0">APIs, widgets, & tools for seamless integration</h5>
                        </div>
                      </div>
                    </td>

                    <td>
                      <img src={done} alt="done" />
                    </td>

                    <td>
                      <img src={help} alt="help" />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="media d-flex align-items-center justify-content-start startdiv">
                        <img className="mr-3" src={t3} alt="t3" />

                        <div className="media-body">
                          <h5 className="mt-0 mb-0">Turnkey embedded insurance-in-a box</h5>
                        </div>
                      </div>
                    </td>

                    <td>
                      <img src={done} alt="done" />
                    </td>

                    <td>
                      <img src={help} alt="help" />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="media d-flex align-items-center justify-content-start startdiv">
                        <img className="mr-3" src={t4} alt="t4" />

                        <div className="media-body">
                          <h5 className="mt-0 mb-0">Licensed sales advisors</h5>
                        </div>
                      </div>
                    </td>

                    <td>
                      <img src={done} alt="done" />
                    </td>

                    <td>
                      <img src={help} alt="help" />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="media d-flex align-items-center justify-content-start startdiv">
                        <img className="mr-3" src={t5} alt="t5" />

                        <div className="media-body">
                          <h5 className="mt-0 mb-0">Customers can compare & shop</h5>
                        </div>
                      </div>
                    </td>

                    <td>
                      <img src={done} alt="done" />
                    </td>

                    <td>
                      <img src={help} alt="help" />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="media d-flex align-items-center justify-content-start startdiv">
                        <img className="mr-3" src={t6} alt="t6" />

                        <div className="media-body">
                          <h5 className="mt-0 mb-0">Help customers protect their home & mortgag</h5>
                        </div>
                      </div>
                    </td>

                    <td>
                      <img src={done} alt="done" />
                    </td>

                    <td>
                      <img src={help} alt="help" />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="media d-flex align-items-center justify-content-start startdiv">
                        <img className="mr-3" src={t7} alt="t7" />

                        <div className="media-body">
                          <h5 className="mt-0 mb-0">Customers can switch carriers & customize policies</h5>
                        </div>
                      </div>
                    </td>

                    <td>
                      <img src={done} alt="done" />
                    </td>

                    <td>
                      <img src={help} alt="help" />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="media d-flex align-items-center justify-content-start startdiv">
                        <img className="mr-3" src={t8} alt="t8" />

                        <div className="media-body">
                          <h5 className="mt-0 mb-0">Top-rated carriers & discounts on bundled insurance</h5>
                        </div>
                      </div>
                    </td>

                    <td>
                      <img src={done} alt="done" />
                    </td>

                    <td>
                      <img src={help} alt="help" />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <div className="intrgrate-bubble-box">
        <div className="container common-container-space">
          <div className="row">
            <div className="col-12">
              <h2>How you can integrate Bubble into your business</h2>
            </div>
          </div>

          <Row xs={1} md={2} className="g-4">
            <Col>
              <Card>
                <div className="cardimgbox">
                  <Card.Img className="boxlogoimg" src={brand} alt="brand" />
                </div>

                <Card.Body>
                  <Card.Title>Co-branded Page</Card.Title>

                  <Card.Text className="deskslider">
                    One of the fastest ways to get started. Once Bubble and you say "Yes we do", in a blink, we can go live where you can begin presenting your users with Bubble's insurance quotes.
                  </Card.Text>

                  <div className="mobslider">
                    <div className="mobtext">
                      <Card.Text>
                          One of the fastest ways to get started. Once Bubble and you say "Yes we do", in a blink, we can go live where you can begin presenting your users with Bubble's insurance quotes.
                      </Card.Text>
                    </div>

                    <div className="containerlist">
                      <input type="checkbox" id="check_new" />

                      <label htmlFor="check_new"></label>

                      <div className="showlist">
                        <Card.Text>
                          One of the fastest ways to get started. Once Bubble and you say "Yes we do", in a blink, we can go live where you can begin presenting your users with Bubble's insurance quotes.
                        </Card.Text>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card>
                <div className="cardimgbox">
                  <Card.Img className="boxlogoimg" src={widgets} alt="widgets" />
                </div>

                <Card.Body>
                  <Card.Title>Widgets</Card.Title>

                  <Card.Text className="deskslider">
                    By simply adding a line of code to your website, customers can see their insurance quotes. We take care of the rest. We also have our proprietary HomeScore and Bubble LifeScore<sup>TM</sup> Widgets which give your users information about the risks associated with their property and life around it.
                  </Card.Text>

                  <div className="mobslider">
                    <div className="mobtext">
                      <Card.Text>
                        By simply adding a line of code to your website, customers can see their insurance quotes. We take care of the rest. We also have our proprietary HomeScore and Bubble LifeScore<sup>TM</sup> Widgets which give your users information about the risks associated with their property and life around it.
                      </Card.Text>
                    </div>

                    <div className="containerlist">
                      <input type="checkbox" id="check_new2" />

                      <label htmlFor="check_new2"></label>

                      <div className="showlist">
                        <Card.Text>
                          By simply adding a line of code to your website, customers can see their insurance quotes. We take care of the rest. We also have our proprietary HomeScore and Bubble LifeScore<sup>TM</sup> Widgets which give your users information about the risks associated with their property and life around it.
                        </Card.Text>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card>
                <div className="cardimgbox">
                  <Card.Img className="boxlogoimg" src={api} alt="api" />
                </div>

                <Card.Body>
                  <Card.Title>APIs</Card.Title>

                  <Card.Text className="deskslider">
                    Control user experience and the look & feel for your customers. Use our Quote (Home, Auto, and Life), HomeScore, and Bubble LifeScore<sup>TM</sup> APIs. This is a deep integration that gives you full control.
                  </Card.Text>

                  <div className="mobslider">
                    <div className="mobtext">
                      <Card.Text>
                        Control user experience and the look & feel for your customers. Use our Quote (Home, Auto, and Life), HomeScore, and Bubble LifeScore<sup>TM</sup> APIs. This is a deep integration that gives you full control.
                      </Card.Text>
                    </div>

                    <div className="containerlist">
                      <input type="checkbox" id="check_new3" />

                      <label htmlFor="check_new3"></label>

                      <div className="showlist">
                        <Card.Text>
                          Control user experience and the look & feel for your customers. Use our Quote (Home, Auto, and Life), HomeScore, and Bubble LifeScore<sup>TM</sup> APIs. This is a deep integration that gives you full control.
                        </Card.Text>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card>
                <div className="cardimgbox">
                  <Card.Img className="boxlogoimg" src={upload} alt="upload" />
                </div>

                <Card.Body>
                  <Card.Title>LOS/POS <br/>(1003, MISMO file upload)</Card.Title>

                  <Card.Text className="deskslider">
                    Upload 1003 or MISMO files or push the same data via API or Webhook, and our "Bubb Magic APIs" give you an instant quote for Home and Life Insurance.
                  </Card.Text>

                  <div className="mobslider">
                    <div className="mobtext">
                      <Card.Text>
                        Upload 1003 or MISMO files or push the same data via API or Webhook, and our "Bubb Magic APIs" give you an instant quote for Home and Life Insurance.
                      </Card.Text>
                    </div>

                    <div className="containerlist">
                      <input type="checkbox" id="check_new4" />

                      <label htmlFor="check_new4"></label>

                      <div className="showlist">
                        <Card.Text>
                          Upload 1003 or MISMO files or push the same data via API or Webhook, and our "Bubb Magic APIs" give you an instant quote for Home and Life Insurance.
                        </Card.Text>
                      </div>
                    </div>
                  </div>

                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <div className="insurance-partners">
        <div className="container common-container-space">
          <div className="row">
            <div className="col-12">
              <h2>Our top-rated insurance partners</h2>
            </div>
          </div>

          <div className="insurancepartner">
            <div className="row">
              <div className="col-12">
                <div className="logolistbox deskslider">
                  <div className="logolist">
                    <a href="/homeowners-insurance/nationwide/">
                      <img src={logo1} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a href="/stillwater/">
                      <img src={logo2} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a href="/next-by-pacific-life/">
                      <img src={logo3} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a href="/homeowners-insurance/safeco/">
                      <img src={logo4} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a href="/SBLI/">
                      <img src={logo5} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a href="https://cdn.back9ins.com/product-guides/105-LAA2233.pdf" target="_blank" rel="noreferrer">
                      <img src={logo6} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a href="https://cdn.back9ins.com/product-guides/665-1010826-00002_Term%2BEssential%2BTerm%2BElite%2BConsumer%2BBrochure%2B(1).pdf" target="_blank" rel="noreferrer">
                      <img src={logo7} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a href="https://cdn.back9ins.com/product-guides/550-608NM-1%2BADDvantage%2BTerm%2BConsumer%2BGuide.pdf" target="_blank" rel="noreferrer">
                      <img src={logo8} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a href="https://cdn.back9ins.com/product-guides/441-TransamericaTrendsetterLBBrochure.pdf" target="_blank" rel="noreferrer">
                      <img src={logo9} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a href="https://assurity.dmplocal.com/dsc/collateral/Term_Life_Consumer_Brochure.pdf?d03930b82852e26633e6bac5638c4b2c" target="_blank" rel="noreferrer">
                      <img src={logo10} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a target="_blank" rel="noreferrer">
                      <img src={bamboo} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a target="_blank" rel="noreferrer">
                      <img src={hippo} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist">
                    <a target="_blank" rel="noreferrer">
                      <img src={aegis} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist wslogo">
                    <a href="/wns/" target="_blank" rel="noreferrer">
                      <img src={ws} alt="logo" />
                    </a>
                  </div>

                  <div className="logolist kklogo">
                    <a target="_blank" rel="noreferrer">
                      <img src={kk} alt="logo" />
                    </a>
                  </div>
                </div>
              </div>

              {/* <div className="col-12">
                <div className="mobslider">
                  <Simple images={images}/>
                </div>
              </div> */}

              <div className="col-12 logolistbox">
                <div className="mobslider">
                  <Splide
                    options={ {
                      perPage: 2,
                      pagination: true,
                      drag: true,
                      arrows:true
                    } }
                    aria-label="My Favorite Images"
                  >
                    <SplideSlide className="logolist">
                      <a href="/homeowners-insurance/nationwide/">
                        <img src={logo1} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist">
                      <a href="/stillwater/">
                        <img src={logo2} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist">
                      <a href="/next-by-pacific-life/">
                        <img src={logo3} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist">
                      <a href="/homeowners-insurance/safeco/">
                        <img src={logo4} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist">
                      <a href="/SBLI/">
                        <img src={logo5} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist">
                      <a href="https://cdn.back9ins.com/product-guides/105-LAA2233.pdf" target="_blank" rel="noreferrer">
                        <img src={logo6} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist">
                      <a href="https://cdn.back9ins.com/product-guides/665-1010826-00002_Term%2BEssential%2BTerm%2BElite%2BConsumer%2BBrochure%2B(1).pdf" target="_blank" rel="noreferrer">
                        <img src={logo7} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist">
                      <a href="https://cdn.back9ins.com/product-guides/550-608NM-1%2BADDvantage%2BTerm%2BConsumer%2BGuide.pdf" target="_blank" rel="noreferrer">
                        <img src={logo8} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist">
                      <a href="https://cdn.back9ins.com/product-guides/441-TransamericaTrendsetterLBBrochure.pdf" target="_blank" rel="noreferrer">
                        <img src={logo9} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist">
                      <a href="https://assurity.dmplocal.com/dsc/collateral/Term_Life_Consumer_Brochure.pdf?d03930b82852e26633e6bac5638c4b2c" target="_blank" rel="noreferrer">
                        <img src={logo10} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist commonimg">
                      <a target="_blank" rel="noreferrer">
                        <img src={bamboo} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist commonimg">
                      <a target="_blank" rel="noreferrer">
                        <img src={hippo} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist commonimg">
                      <a target="_blank" rel="noreferrer">
                        <img src={aegis} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist commonimg wslogo">
                      <a href="/wns/" target="_blank" rel="noreferrer">
                        <img src={ws} alt="logo" />
                      </a>
                    </SplideSlide>

                    <SplideSlide className="logolist commonimg">
                      <a target="_blank" rel="noreferrer">
                        <img src={kk} alt="logo" />
                      </a>
                    </SplideSlide>

                  </Splide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="partner-say-box bubble-benefits-wrap-box journey-box-wrap">
        <div className="container common-container-space">
          <div className="row">
            <div className="col-12">
              <h2>What our partners have to say</h2>
            </div>
          </div>

          <div className='row'>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="boxwrap">
                <div className="media d-flex">
                  <img className="mr-3" src={SBLI} alt="Benefit" />

                  <div className="media-body">
                    <h5 className="mt-0">SBLI</h5>

                    <p>partner at san francisco</p>
                  </div>
                </div>

                <ul>
                  <li>
                    Quis placerat. placerat risus tincidunt non Nam sit amet, Praesent quis tempor nisi placerat commodo viverra vehicula.
                  </li>

                  <li>
                    Est. sollicitudin. diam Donec consectetur hendrerit non sollicitudin. sit sed Nunc Quisque urna in volutpat placerat vitae
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4">
              <div className="boxwrap">
                <div className="media d-flex">
                  <img className="mr-3" src={NEXT} alt="Benefit" />

                  <div className="media-body">
                    <h5 className="mt-0">NEXT by Paclife</h5>

                    <p>partner at california</p>
                  </div>
                </div>

                <ul>
                  <li>
                    Quis placerat. placerat risus tincidunt non Nam sit amet, Praesent quis tempor nisi placerat commodo viverra vehicula.
                  </li>

                  <li>
                    Est. sollicitudin. diam Donec consectetur hendrerit non sollicitudin. sit sed Nunc Quisque urna in volutpat placerat vitae
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4">
              <div className="boxwrap">
                <div className="media d-flex">
                  <img className="mr-3" src={Stillwaters} alt="Benefit" />

                  <div className="media-body">
                    <h5 className="mt-0">Stillwaters</h5>

                    <p>partner at texas</p>
                  </div>
                </div>

                <ul>
                  <li>
                    Quis placerat. placerat risus tincidunt non Nam sit amet, Praesent quis tempor nisi placerat commodo viverra vehicula.
                  </li>

                  <li>
                    Est. sollicitudin. diam Donec consectetur hendrerit non sollicitudin. sit sed Nunc Quisque urna in volutpat placerat vitae
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {/* book a demo modal */}

      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Let’s Work Together</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <div className="form-group">
              <input type="text" className="common-ip" id="formGroupExampleInput" placeholder="Name" />
            </div>

            <div className="form-group">
              <input type="text" className="common-ip" id="formGroupExampleInput2" placeholder="Phone Number" />
            </div>

            <div className="form-group">
              <input type="text" className="common-ip" id="formGroupExampleInput" placeholder="Email Id" />
            </div>

            <div className="form-group">
              <input type="text" className="common-ip" id="formGroupExampleInput2" placeholder="Company" />
            </div>

            <div className="form-group">
              <input type="text" className="common-ip" id="formGroupExampleInput" placeholder="Title (optional)" />
            </div>
          </form>

          {/* <p>non id maximus vitae ullamcorper vel enim. eu odio ex maximus In Ut amet, laoreet diam orci sit commodo non vitae commodo quis cursus non. Ut volutpat tincidunt sit leo. Nullam non lacus maximus est. viverra leo.</p>

          <p>non venenatis lacus vel nec Cras lorem. orci sed non. volutpat luctus ex amet, non tortor....</p> */}
        </Modal.Body>

        <Modal.Footer>
          <button className="common-btn-bg orangebg-btn">Submit</button>
        </Modal.Footer>
      </Modal>

    </Layout>
  );
}

export default PartnerWithUs;
